<template>
<div class="collumn_report col ">
	<div v-if="selectReport.items">
		<div class="d-flex align-items-center mt-3">
			<p class="">{{ selectReport.received > 1 ? new Date(selectReport.received*1000).toLocaleString("ru", ) : '-' }}</p>
			<p class="ml-4  ">Сканирование: {{selectReport.items.planet_name}} [{{selectReport.items.planet_path[0]}}:{{selectReport.items.planet_path[1]}}:{{selectReport.items.planet_path[2]}}]</p>
			<img v-if="selectReport.items.report.player && selectReport.items.report.player.status == 'normal'" title="Нормальный" src="img/status/empty_status_icon.png" class="ml-2" height="20px" alt="">
			<img v-if="selectReport.items.report.player && selectReport.items.report.player.status == 'blocked'" title="Заблокированный" src="img/status/block_status_icon.png" class="ml-2" height="20px" alt="">
			<img v-if="selectReport.items.report.player && selectReport.items.report.player.status == 'unattackable'" title="Под защитой" src="img/status/protection_status_icon.png" class="ml-2" height="20px" alt="">
			<img v-if="selectReport.items.report.player && selectReport.items.report.player.status == 'vacation'" title="В отпуске" src="img/status/vacation_status_icon.png" class="ml-2" height="20px" alt="">
			<p class="ml-3 btnsim" @click="goToSimulator(selectReport.items.planet_path[0]+':'+selectReport.items.planet_path[1]+':'+selectReport.items.planet_path[2])">Загрузить в симулятор</p>
			<p class="ml-auto">Владелец: <span class="text-white b">{{selectReport.items.player_name?selectReport.items.player_name:'Нет владельца'}}</span></p>
		</div>



		<div class="row mt-3">
			<div class="col-7">
				<div v-if="!selectReport.items.report.resources" class="heigt_box px-2 pb-1">
					<span class="">Ресурсы:</span>
				</div>
				<div class="minbg px-2 pb-2">
					<div  v-if="selectReport.items.report.resources" class="row pt-1 px-1">
						<div class="col-3 ">
							<p class="m-0 text-center">Титан:</p>
							<p class="yellow_main he2 text-center">{{Math.floor(selectReport.items.report.resources.titanium).toLocaleString('ru')}}</p>
						</div>
						<div class="col-3 ">
							<p class="m-0 text-center">Кремний:</p>
							<p class="yellow_main he2 text-center">{{Math.floor(selectReport.items.report.resources.silicon).toLocaleString('ru')}}</p>
						</div>
						<div class="col-3 ">
							<p class="m-0 text-center">Антиматерия:</p>
							<p class="yellow_main he2 text-center">{{Math.floor(selectReport.items.report.resources.antimatter).toLocaleString('ru')}}</p>
						</div>
						<div class="col-3 ">
							<p class="m-0 text-center">Энергия:</p>
							<p class="yellow_main he2 text-center">{{Math.floor(selectReport.items.report.resources.energy).toLocaleString('ru')}}</p>
						</div>
					</div>
					<p v-if="!selectReport.items.report.resources" class=" px-2 pb-0 pt-1">Нет данных</p>

				</div>
			</div>
			<div class="col-5 ">
				<!-- <div class="heigt_box px-2 pb-1">
					<span class="">Обломки на орбите:</span>
				</div> -->
				<div class="minbg fonobl px-2 pb-2">
					<div class="fonobl_mask">
					</div>
						<div class="row pt-1 px-1">
							<div class="col-6 text-center">
								<p class="m-0">Титан:</p>
								<p class="yellow_main he2">{{Math.floor(selectReport.items.report.debris.titanium).toLocaleString('ru')}}</p>
							</div>
							<div class="col-6 text-center">
								<p class="m-0">Кремний:</p>
								<p class="yellow_main he2">{{Math.floor(selectReport.items.report.debris.silicon).toLocaleString('ru')}}</p>
							</div>
						</div>
				</div>

			</div>
		</div>

		<div class="row mt-3">
			<div class="col-4 px-3">
				<div v-if="!selectReport.items.report.buildings" class="heigt_box px-2 pb-1">
					<span class="">Здания:</span>
				</div>
				<div class="d-flex minbg px-1 pb-2 flex-wrap">
					<div v-for="(build, index) in selectReport.items.report.buildings"  :key="index" :class="{'px-1':1, minbg2:1,  'col-4':1, 'mt-2':1} " >
						<div class="minbg2 w-100  position-relative" :style="'background:url(/img/report/'+index+'.jpg); background-position: center;background-repeat: no-repeat; background-size: cover;'">
							<div class="mask">
								<div class="h-100 w-100 position-relative">
									<span class="ml-2 mask_text ">{{namesUnit[index]}}</span>

									<span class="ml-auto mask_lvl yellow_main">{{build}}</span>
								</div>
							</div>
						</div>
						<!-- <img :src="'/img/report/'+index+'.jpg'" class="imgRect" alt=""> -->
						<!-- <span class="ml-2">{{namesUnit[index]}}</span> -->
						<!-- <span class="ml-auto yellow_main">{{build}}</span> -->
					</div>

					<p v-if="!selectReport.items.report.buildings" class=" px-2 w-100 pb-0 pt-1">Нет данных</p>
				</div>
			</div>

			<div class="col-4">
				<div v-if="!selectReport.items.report.defences
				" class="heigt_box px-2 pb-1">
					<span class="">Оборона:</span>
				</div>
				<div class="d-flex minbg px-1 pb-2 flex-wrap">
					<div v-for="(build, index) in selectReport.items.report.defences"  :key="index" class="px-1 mt-2 col-4 mt-1 " >
						<div class="minbg2 w-100 position-relative" :style="'background:url(/img/report/'+index+'.jpg); background-position: center;background-repeat: no-repeat; background-size: cover;'">
							<div class="mask">
								<div class="h-100 w-100 position-relative">
									<span class="ml-2 mask_text ">{{namesUnit[index]}}</span>
									<span class="ml-auto mask_lvl yellow_main">{{build}}</span>
								</div>
							</div>
						</div>
					</div>
					<p v-if="!selectReport.items.report.defences" class=" px-2 w-100 pb-0 pt-1">Нет данных</p>
				</div>

			</div>

			<div class="col-4">
				<div v-if="!selectReport.items.report.spaceships"  class="heigt_box px-2 pb-1">
					<span class="">Корабли:</span>
				</div>
				<div class="d-flex minbg px-1 pb-2 flex-wrap">
					<div v-for="(build, index) in selectReport.items.report.spaceships"  :key="index" class="px-1 mt-2 col-4 mt-1 " >
						<div class="minbg2 w-100 position-relative" :style="'background:url(/img/report/'+index+'.jpg); background-position: center;background-repeat: no-repeat; background-size: cover;'">
							<div class="mask">
								<div class="h-100 w-100 position-relative">
									<span class="ml-2 mask_text ">{{namesUnit[index]}}</span>
									<span class="ml-auto mask_lvl yellow_main">{{build}}</span>
								</div>
							</div>
						</div>
					</div>
					<p v-if="!selectReport.items.report.spaceships" class=" px-2 w-100 pb-0 pt-1">Нет данных</p>
				</div>
				<div v-if="selectReport.items.report.spaceships" class="heigt_box px-2 text-center align-items-center justify-content-between pb-1 d-flex">
					<div v-if="false" class="arrow-left">
						сюда
					</div>
					<span class="mx-auto">{{selectReport.items.player_name}}</span>
					<div v-if="false" class="arrow-right">
						туда
					</div>
				</div>
			</div>

		</div>

		<div class="row  mt-3">
			<div class="col-12">
				<div v-if="!selectReport.items.report.technologies" class="heigt_box px-2 pb-1">
					<span class="">Технологии:</span>
				</div>
			</div>
      <div class="col-12 ">
        <div class="container-fluid p-0">
          <div class="d-flex flex-wrap minbg w-100 px-1 pb-2">

							<div v-for="(build, index) in selectReport.items.report.technologies"  :key="index" class="px-1 mt-2 minbg2" style="margin-left:0.5px;"  >
								<div class="minbg2 w-100 position-relative" :style="'background:url(/img/report/'+index+'.jpg); background-position: center;background-repeat: no-repeat; background-size: cover;'">
									<div class="mask">
										<div class="h-100 w-100 position-relative">
											<span class="ml-2 mask_text ">{{namesUnit[index]}}</span>
											<span class="ml-auto mask_lvl yellow_main">{{build}}</span>
										</div>
									</div>
								</div>
							</div>


						<p class="col-12 " v-if="!selectReport.items.report.technologies">Нет данных</p>
          </div>
        </div>
      </div>

		</div>

		<div class="row mt-3" v-if="false">
			<div class="col-12">
				<div class="heigt_box px-2 pb-1">
					<span class="">Флот:</span>
				</div>
			</div>
			<div class="col-12" v-if="selectReport.items.report.fleets && selectReport.items.report.fleets.length > 0">

				<table class="w-100">
					<thead>
						<tr>
							<td>Игрок</td>
							<td>Разведзонд: </td>
							<td>Шаттл: </td>
							<td>Транспорт: </td>
							<td>Истребитель:</td>
							<td>Истребитель:</td>
							<td>Штурмовик:</td>
							<td>Фрегат:</td>
							<td>Галактион:</td>
							<td>Разрушитель:</td>
						</tr>
					</thead>
					<tbody >
						<tr v-for="(technologie, index) in selectReport.items.report.fleets"  :key="index">
							<td>Alabay</td>
							<td class="yellow_main">157</td>
							<td class="yellow_main">12</td>
							<td class="yellow_main">23</td>
							<td class="yellow_main">43</td>
							<td class="yellow_main">0</td>
							<td class="yellow_main">0</td>
							<td class="yellow_main">0</td>
							<td class="yellow_main">0</td>
							<td class="yellow_main">0</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="col-12 " v-else><p class="minbg px-2 pb-2 pt-1">Нет данных</p></div>

		</div>


	</div>
</div>
</template>

<script>
export default {
	props: {
		selectReport: Object,
	},
	mounted(){
		// console.log(this.selectReport.items.report.defences);
	},
	data(){
		return {
			namesUnit: {shuttle:"Шаттл",fighter:"Истребитель",transport: "Транспорт",energodrone:"Энергодрон",attacker:"Штурмовик",frigate:"Фрегат",galaction:"Галактион",destroyer:"Разрушитель",bombardier:"Бомбардир",colossus:"Колосс",scout:"Разведзонд",collector:"Коллектор",pioneer:"Первопроходец",corvette:"Корвет",big_energy_shield:"Большой энергокупол",small_energy_shield:"Малый энергокупол",lepton_cannon:"Лептонная пушка",photon_cannon:"Фотонная пушка",graviton_weapon:"Гравитонное орудие",ultraviolet_laser:"Ультрафиолетовый лазер",infrared_laser:"Инфракрасный лазер",missile_block:"Ракетный блок",titanium_mine:"Титановая шахта",collider:"Коллайдер",titanium_silo:"Склад титана",robofactory:"Робофабрика",nanofactory:"Нанофабрика",annihilation_reactor:"Аннигиляционный реактор",dock:"Док",teleport:"Телепорт",science_center:"Научный центр",filling_base:"Заправочная база",antimatter_storage:"Склад антиматерии",silicon_silo:"Склад кремния",silicon_mine:"Кремниевая шахта",neutrino_power_station:"Нейтринная электростанция",planetary_energy_center:"Планетарный энергоузел",nanotechnology_center:"Центр нанотехнологий",terrestrial_telescope:"Наземный телескоп",cosmic_telescope:"Космический телескоп",
			planet_scanning:"Планетарное сканирование",navigation:"Навигация",armament:"Вооружения",ship_protection:"Защита кораблей",energy_shields:"Энергетические поля",energetics:"Энергетика",subspace_travel:"Перемещение в подпространст.",baryonic_engine:"Барионный двигатель",annihilation_engine:"Аннигиляционный двигатель",subspace_engine:"Подпространственный двигатель",military_lasers:"Боевые лазеры",photonic_weapon:"Фотонное оружие",leptonic_weapon:"Лептонное оружие",tachyon_scan:"Тахионное сканирование",planet_exploration:"Освоение планет",vibrotron:"Вибротрон",military_plant: "Центр обороны"},

		}
	},
	methods:{
		goToSimulator(path){
			localStorage.setItem("pathSimulator", path)
			this.$router.push("Simulator")
		},
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fonobl{
	position: relative;
	background: url('/img/ringsreport.webp')!important;
	background-position: center!important;
	background-size: cover!important;
}
.fonobl_mask{
	position: absolute;
	background: var(--right-fight-fonobl-mask-bg);
	left: 0;
	right: 0;
	height: 100%;
	width:100%;
}
.minbg {
	background: var(--right-light-minbg-bg);
}
.mask_text{
	overflow-wrap: anywhere;
	position: absolute;
	left: -5px;
	bottom:0;
	font-size: 10px;
}
.mask_lvl{
	position:absolute;
	left: 5px;
	top: 0;
}
.mask{
	position: absolute;
	/* background: ; */
	background: var(--right-light-mask-bg);
	left: 0;
	right: 0;
}
.btnsim{
	background: var(--right-light-btnsim-bg);
	padding: 5px 15px;
	cursor:pointer;
}
.btnsim:hover{
	background: var(--right-light-btnsim-hover);
}
.minbg2{
	box-shadow: 0 0 10px var(--right-light-minbg2-box);
	overflow-wrap: break-word;
	height: 90px;
	width: 105px;
	/* width: 100%; */
	/* background: #393A40; */
}

table {
	background: var(--right-light-table-bg);
}

td {
	border: 1px solid var(--right-light-td-border-color);
	text-align: center;
	font-size: 15px;
	padding: 0px !important;
}

p {
	margin: 0;
	font-size: 15px;
}

span {
	font-size: 15px;
}

.collumn_report {
	height: 100%;
	padding-bottom: 30px;
	overflow: auto;
	/* min-height: 100vh; */
	background: var(--right-light-column-report);
	color: var(--right-light-column-report-color);
}

.he1 {
	font-size: 20px;
}

.he2 {
	font-size: 17px;
}

.yellow_main {
	color: #FDFFB1;
}

.heigt_box {
	font-size: 12px;
	background: var(--right-light-height-box-bg);
}

.border_box {
	border-bottom: 1px solid var(--right-border-box-color);
}
.imgRect{
	height: 40px;
	width: 40px;
	box-shadow: 5px 0px 5px var(--right-scan-imgrect-box);
	/* filter: drop-shadow(5px 0px 5px rgba(0, 0, 0, 0.25)); */

}
.border_bottom {
	border-bottom: 1px solid var(--right-scan-border-bottom-color);
}
</style>
