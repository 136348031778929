<template>
<div class="collumn_report col" >
	<div class="" v-if="selectReport.message">
		<div class="d-flex">
			<p>Сообщение маяка.</p>
			<p class="ml-auto">{{new Date(Date.parse(selectReport.time)).toLocaleString("ru", )}}</p>
		</div>
		<div class="heigt_box px-2 py-1">
			<p class="mb-0 ">Текст сообщения</p>
		</div>
		<div class="minbg p-2">
			<p class="mb-0 ">{{selectReport.message}}</p>

		</div>
	</div>
</div>
</template>

<script>
export default {
	props: {
		selectReport: Object,
	},

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.collumn_report {
	height: 100%;
	padding-bottom: 30px;
	overflow: auto;
	/* min-height: 100vh; */
	background: var(--right-light-column-report-bg);
	color: var(--right-light-column-report-color);
}
.minbg {
	background: var(--right-light-minbg-bg);
}
.heigt_box {
	background: var(--right-light-height-box-bg);
}

</style>
