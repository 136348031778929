<template>
  <div class="collumn_report col " >
    <div v-if="selectReport.items">
      <div class="d-flex align-items-center mt-3 mb-1">
        <p class="">{{selectReport.items.report.real_time?(new Date(selectReport.items.report.real_time*1000).toLocaleString("ru")):'15.05.2000, 11:30:00'}}</p>
        <p class="ml-3 ">Боевой доклад с планеты {{selectReport.items.planet_name}} [{{selectReport.items.planet_path[0]}}:{{selectReport.items.planet_path[1]}}:{{selectReport.items.planet_path[2]}}]</p>
        <p class="ml-auto">Бой выигрывает: <span class="text-white b">{{names[selectReport.items.report.status]}}</span></p>
      </div>
      <div class="d-flex align-items-center mt-0 mb-3">
        <p class="m-0">Атакующий: <b>{{selectReport.items.report.players[0].name}}</b></p>
        <p class="m-0 ml-4">Обороняющийся: <b>{{selectReport.items.report.players[1].player_name}}</b> </p>
      </div>


      <div class="row mt-1">
        <div class="col-7 " >
          <!-- <div class="heigt_box px-2 pb-1">
            <span class="">Добыча общая:</span>
          </div> -->
          <div class="minbg px-2 pb-2">
            <div class="row py-1 px-1">
              <div class="col-4 text-center">
                <p class="m-0">Титан:</p>
                <p class="yellow_main m-0 he2">{{allRes.titanium.toLocaleString('ru-RU', {maximumFractionDigits:0} )}}</p>
              </div>
              <div class="col-4 text-center">
                <p class="m-0">Кремний:</p>
                <p class="yellow_main m-0 he2">{{allRes.silicon.toLocaleString('ru-RU', {maximumFractionDigits:0} )}}</p>
              </div>
              <div class="col-4 text-center">
                <p class="m-0">Антиматерия:</p>
                <p class="yellow_main m-0 he2">{{allRes.antimatter.toLocaleString('ru-RU', {maximumFractionDigits:0} )}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-5">
          <!-- <div class="heigt_box px-2 pb-1">
            <span class="">Обломки на орбите:</span>
          </div> -->
          <div class="minbg fonobl px-2  pb-2">
            <div class="fonobl_mask"></div>
            <div class="row py-1 px-1">
              <div class="col-6 text-center ">
                <p class="m-0">Титан:</p>
                <p class="yellow_main m-0 he2">{{selectReport.items.report.debris.titanium.toLocaleString('ru-RU', {maximumFractionDigits:0})}}</p>
              </div>
              <div class="col-6 text-center ">
                <p class="m-0">Кремний:</p>
                <p class="yellow_main m-0 he2">{{selectReport.items.report.debris.silicon.toLocaleString('ru-RU', {maximumFractionDigits:0})}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div class="row mt-2" v-if="selectReport.items.report.loot">
        <div class="col-12">
          <div class="heigt_box px-2 pb-1">
            <span class="">Добыча детально:</span>

          </div>
        </div>
        <div class="col-12">
          <table class="w-100">
            <thead>
              <tr>
                <td rowspan="2" >Флот</td>
                <td colspan="3">Добыча: </td>
              </tr>
              <tr>
                <td>Титан: </td>
                <td>Кремний: </td>
                <td>Антиматерия: </td>


              </tr>
            </thead>
            <tbody>
              <tr v-for="(flot, index) in selectReport.items.report.loot"  :key="index">
                <td>{{flot.fleet}}</td>
                <td class="yellow_main">{{flot.titanium.toLocaleString('ru-RU', {maximumFractionDigits:0})}}</td>
                <td class="yellow_main">{{flot.silicon.toLocaleString('ru-RU', {maximumFractionDigits:0})}}</td>
                <td class="yellow_main">{{flot.antimatter.toLocaleString('ru-RU', {maximumFractionDigits:0})}}</td>

              </tr>
            </tbody>
          </table>
        </div>
      </div>


      <div class="row mt-3">
        <div class="col-12">
          <p class=" mb-2">Флоты соперников встретились
            <span class="">
              {{selectReport.items.report.real_time?(new Date(selectReport.items.report.real_time*1000).toLocaleString("ru")):'15.05.2000, 11:30:00'}}
            </span>
            <br>(GameTime:{{selectReport.items.report.game_time?(new Date(selectReport.items.report.game_time).toLocaleString("ru")):'15.05.2000, 11:30:00'}}). </p>
        </div>
      </div>

      <div class="row justify-content-around px-2">

        <!-- Ячейки расчета -->
        <Card ref="attack" :step="selectRound" :selectReport="selectReport" сname="attacker"></Card>
        <Card ref="defer" :step="selectRound" :selectReport="selectReport" сname="defender"></Card>



        <div class="col-12 d-flex mt-2  px-0">
          <div class="w-100 text-center" v-if="selectReport.items.report.rounds && selectReport.items.report.rounds.length>0" style="color: rgba(255, 255, 255, 0.44); line-height: 1.4; max-width:100px;">Раунды:</div>
          <div @click="clickStep(step.round)" :class="'main_btn_1 mx-1 '+ (step.round==selectRound?'select':'') " v-for="step in selectReport.items.report.rounds"  :key="step.round">{{step.round+1}}</div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Card from '@/components/report/compon/CardComponent.vue'

export default {
  components:{
    Card,
  },
  props: {
    selectReport: Object,
  },
  computed:{
    allRes(){
      let res = {titanium:0,silicon:0,antimatter:0}
      if (!this.selectReport.items.report.loot) return res

      this.selectReport.items.report.loot.forEach((flot) => {
        res.titanium += flot.titanium
        res.silicon += flot.silicon
        res.antimatter += flot.antimatter
      });
      return res
    }
  },
  data(){
    return{
      names: {attacker:"Атакующий", defender:"Обороняющийся"},
      selectRound: 0,
    }
  },
  methods:{
    clickStep(step){
      this.selectRound = step
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fonobl{
	position: relative;
	background: url('/img/ringsreport.webp')!important;
	background-position: center!important;
	background-size: cover!important;
}
.fonobl_mask{
	position: absolute;
	background: var(--right-fight-fonobl-mask-bg);
	left: 0;
	right: 0;
	height: 100%;
	width:100%;
}
table{
  background: var(--right-fight-table-bg);
}
.minbg{
  background: var(--right-fight-table-bg);
}
td{
  border: 1px solid var(--right-fight-td-border-color);
  text-align: center;
  font-size: 14px;
  padding: 0px!important;
}
p{
  margin: 0;
  font-size:15px;
}
span{
  font-size: 15px;
}

.collumn_report{
  min-height: 100vh;
  background: var(--right-fight-column-report-bg);
  color: var(--right-fight-column-report-color);
}
.he1{
  font-size: 20px;
}
.he2{
  font-size: 17px;
}
.yellow_main{
  color: var(--right-fight-yellow-main-color);
}

.heigt_box{
  background: var(--right-fight-height-box-bg);
}
.main_box{
  background: var(--right-fight-main-box-bg);
  padding-bottom: 40px;
}

.main_btn_1{
  padding: 0 35px;
  cursor: pointer;
  line-height: 1.4;
  font-size: 15px;
  background: var(--right-fight-main-btn-bg);
}
.main_btn_1:hover{
  background: var(--right-fight-main-btn-bg-hover);
}
.main_btn_1.select{
  background: var(--right-fight-main-select-bg);
  color: black;
}

</style>
