<template>
  <div v-show="show" :class="'red position-relative ' +(brnot?'':' box')" :style="{height: height+'px', width: width}">
    <div :class="'green blue position-relative ' +(brnot?'':' box')" :style="{height: height+'px', width: filled+'%'}">
    </div>
  </div>
</template>

<script>
export default {
  mounted(){
    if (this.fill == -1) {
      this.show = false
    }
    this.filled = this.fill<101?this.fill:100
  },
  props: {
    height: String,
    width: String,
    fill: String,
    brnot: Boolean,
  },
  data(){
    return{
      filled: '100',
      show: true,
    }
  },
  watch:{
    fill(fill){
      this.filled = fill<101?fill:100
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .blue{
    /* background: #17a2b8!important; */
  }
  .red:not(.shild){
    background: #F75050;
  }
  .box{
    border-radius: 0 0 5px 5px;
  }
  .green{
    transition: width 1s cubic-bezier(0, 0, 1, 1) 100ms;
    background:rgb(68 142 66);
  }

  .shild>.blue{
    transition: width 1s cubic-bezier(0, 0, 1, 1) 100ms;
    background:#17a2b8!important;
  }

</style>
