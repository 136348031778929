<template>
<div class="posive h-100">
    <NavComponent />

    <div class="container-fluid overflow-hidden h-100">
        <div class="row hei100">
            <div class="preload" style="margin-top:30px;" v-if="load">
                <div id="squaresWaveG">
                    <div id="squaresWaveG_1" class="squaresWaveG"></div>
                    <div id="squaresWaveG_2" class="squaresWaveG"></div>
                    <div id="squaresWaveG_3" class="squaresWaveG"></div>
                    <div id="squaresWaveG_4" class="squaresWaveG"></div>
                    <div id="squaresWaveG_5" class="squaresWaveG"></div>
                    <div id="squaresWaveG_6" class="squaresWaveG"></div>
                    <div id="squaresWaveG_7" class="squaresWaveG"></div>
                    <div id="squaresWaveG_8" class="squaresWaveG"></div>
                </div>
            </div>

            <LeftComponent 
                @openedFun="openedFun" 
                :newNotification="newNotification" 
                :loadingReport="loadingReport" 
                :load="load" 
                :selectF="selectF" 
                :selectThema="selectThema" 
                :selectT="selectT" 
                :selectReport="selectReport" 
                :reports="reportsFiltr" 
                style="padding-top: 15px;" />

            <RigthComponentScan 
                style="padding-top: 20px;" 
                v-if="selectThema == 'Scan'" 
                :selectReport="selectReport" 
                :key="selectReport.received" />

            <RigthComponentFight 
                style="padding-top: 20px;" 
                v-if="selectThema == 'Fight'" 
                :selectReport="selectReport" 
                :key="selectReport.received" />

            <RigthComponentLigth 
                style="padding-top: 20px;" 
                v-if="selectThema == 'Ligth'" 
                :selectReport="selectReport" 
                :key="'Ligth'" />

        </div>
    </div>

    <LogComponent />
</div>
</template>

<script>
//import store from '@/store'

import LeftComponent from '@/components/report/LeftComponent.vue'
import RigthComponentScan from '@/components/report/RigthComponentScan.vue'
import RigthComponentFight from '@/components/report/RigthComponentFight.vue'
import RigthComponentLigth from '@/components/report/RigthComponentLigth.vue'
import NavComponent from '@/components/basic_component/NavComponent.vue'
import LogComponent from '@/components/basic_component/LogComponent.vue'

export default {
    name: 'Report',
    components: {
        LeftComponent,
        RigthComponentScan,
        RigthComponentFight,
        RigthComponentLigth,
        NavComponent,
        LogComponent,
    },
    created() {
        this.token = localStorage.getItem("glxtoken")
    },
    mounted() {
        this.getNumberNotification()
        this.getReports()
        this.timerId = setInterval(this.getNumberNotification, 10000);
    },

    data() {
        return {
            page: 1,
            load: false,
            end: false,
            scoutings: [],
            figths: [],
            ligths: [],
            token: false,
            reports: [],
            selectReport: {},
            selectThema: "Scan",
            newNotification: {
                sky: 0,
                battle: 0,
                ligth: 0,
            },
            timerId: '',

        }
    },
    methods: {

        dopReports(){
            let type = this.selectThema == "Scan" ? 0 : 1
            this.load = true
            this.$axios.get("info/reports?type=" + type + "&page=1").then(res => {
                this.load = false
                // console.log(res.data.data);
                let numb = 0
                for (numb = 0; numb < res.data.data.length; numb++) {
                    res.data.data[numb]
                    if (res.data.data[numb].id == this.reports[0].id) {
                        break
                    }
                }
                for (numb; numb > 0; numb--) {
                    this.reports.unshift(res.data.data[numb-1])
                }
            }).catch(err => console.log(err))

        },
        getNumberNotification(){
            this.$axios.get("info/reports/amount").then(res => {
                if (res.data.length == 0) {
                    this.newNotification.sky = 0
                    this.newNotification.battle = 0
                    return
                }
                res.data.forEach(item => {
                    if (item.type == 'sky'){
                        // if (this.newNotification.sky < item.amount && this.selectThema == "Scan") {
                        // 	this.dopReports()
                        // }
                        this.newNotification.sky = item.amount
                    }else{
                        // if (this.newNotification.battle < item.amount && this.selectThema == "Fight") {
                        // 	this.dopReports()
                        // }
                        this.newNotification.battle = item.amount
                    }
                })

            }).catch(err => console.log(err))
            
            if (!this.isProVersion) {
                return;
            }

            this.$axios.get("signals/messages/amount").then(res => {
                this.newNotification.ligth = res.data.amount
            }).catch(err => console.log(err))

        },
        getLigths(){

            if (!this.isProVersion) {
                return this.reports = []
            }

            this.load = true
            this.$axios.get("signals/messages?take=150&page="+this.page).then(res => {
                this.load = false

                if (res.data.length < 50) this.end = true
                else this.end = true

                if (this.page > 1)
                    this.ligths = this.ligths.concat(res.data.sort((a, b) => {
                        if (!b.unopened)
                            return -1
                    }))

                else
                    this.ligths = res.data.sort((a, b) => {
                        if (new Date(b.time)/1000 < new Date(a.time)/1000)
                            return 1
                        if (new Date(b.time)/1000 > new Date(a.time)/1000)
                            return -1
                    }).sort((a, b) => {
                        if (!b.unopened)
                            return -1
                    })


                this.reports = this.ligths

                this.selectF(0)

            }).catch(() => {
                this.load = false
                this.reports = []
            })
        },
        getReports() {
            if (this.load) {
                return
            }
            console.log(this.load, this.page);
            if (this.selectThema == "Ligth") {
                return this.getLigths()
            }
            let type = this.selectThema == "Scan" ? 0 : 1
            this.load = true
            this.$axios.get("info/reports?type=" + type + "&page=" + this.page).then(res => {
                this.load = false
                if (res.data.data.length < 50) this.end = true
                else this.end = false
                if (type == 0) {
                    if (this.page > 1)
                        this.scoutings = this.scoutings.concat(res.data.data)
                    else
                        this.scoutings = res.data.data
                    this.reports = this.scoutings
                } else {
                    if (this.page > 1)
                        this.figths = this.figths.concat(res.data.data)
                    else
                        this.figths = res.data.data
                    this.reports = this.figths
                }
                this.selectF(0)
            }).catch(err => console.log(err))
        },
        loadingReport() {
            if (this.end || this.load) return
            this.page += 1
            this.getReports()
        },
        //Выбор строки.
        selectF(i) {
            if (this.reports[i] == undefined) {
                this.selectReport = {}
                return
            }
            if (this.selectReport !== this.reportsFiltr[i]) {
                this.selectReport = this.reportsFiltr[i]
                if(this.selectReport.unopened){
                        this.openedFun(this.selectReport.id)
                }
            }
        },
        openedLigth(id, ){
            this.$axios.put("signals/messages", id).then(() => {
                this.selectReport.unopened = false
                this.newNotification.ligth -=1
                this.getNumberNotification()
            }).catch(err => console.log(err))
        },
        openedFun(id = false){
            let bodyFormData = new FormData();
            if (id) {
                bodyFormData.append('id', id);
            }
            if(this.selectThema == "Ligth"){
                return this.openedLigth(bodyFormData)
            }
            if (!id) {
                this.openedLigth(bodyFormData)
            }
            this.$axios.post("info/reports/opened", bodyFormData).then(() => {
                // console.log(this.selectReport.unopened);
                if (this.selectReport.unopened){
                    this.selectReport.unopened = false
                    if (this.selectThema == 'Scan')
                    this.newNotification.sky -=1
                    else
                    this.newNotification.battle -=1
                }
                this.getNumberNotification()
                if (!id) {
                    this.page = 1
                    this.reports = []
                    this.getReports()
                }
            }).catch(err => console.log(err))
        },
        selectT(T) {
            this.reports = []
            this.page = 1
            this.selectThema = T
            this.getReports()
        }
    },
    computed:{
        reportsFiltr(){
            return this.reports
            // let arr = this.reports.slice
        },
        isProVersion() {
            return this.$store.getters.isProVersion
        }
    },
    destroyed(){
        clearInterval(this.timerId)
    },
}
</script>
<style media="screen" scoped="">
body{
  overflow-y: hidden!important;
}
.posive{
    position: relative!important;
}
.hei100{
    height: calc(100% - 50px);
}
  .preload{
    position:absolute;
    top: 0;
    left: 0;
    z-index: 33333;
    height: 10px;
    width: 100%;
  }
    .podnav{
        position: absolute!important;
    }
</style>
