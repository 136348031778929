<template>
  <div class="collumn_report col h-100 px-0">
    <p class=" mb-0 px-3 mt-3 d-flex justify-content-between"><span class="he1">Отчеты</span> <a @click="$emit('openedFun')" style="font-size:12px; cursor:pointer; margin-top:12px;" class="alink">Пометить прочитанными</a> </p>

    <div class="container-fluid mt-3">
      <div class="d-flex select-category">
        <div :class="(selectThema=='Scan'?'selectTh':'noSelect')+' position-relative d-flex'" data-intro="#left">  <a @click="selectT('Scan'), numSelect = 0"  href="#/reports">Разведка </a> <div class="curcl_mess ml-2">{{ newNotification.sky | notifyCount}}</div></div>
        <div :class="(selectThema=='Fight'?'selectTh':'noSelect')+ ' position-relative d-flex'">  <a @click="selectT('Fight'), numSelect = 0"  href="#/reports">Боевой доклад</a> <div class="curcl_mess ml-2">{{ newNotification.battle | notifyCount}}</div></div>
        <div :class="(selectThema=='Ligth'?'selectTh':'noSelect')+ ' position-relative d-flex'">  <a @click="selectT('Ligth'), numSelect = 0"  href="#/reports">Маяки</a> <div class="curcl_mess ml-2">{{ newNotification.ligth | notifyCount }}</div></div>
      </div>
    </div>

    <table class="w-100 mt-2">
      <thead>
        <th style="width:10px;"></th>
        <th style="width:130px;">Дата</th>
        <th v-if="selectThema == 'Ligth'">Сообщение</th>
        <th v-if="selectThema != 'Ligth'">Планета</th>
        <th v-if="selectThema != 'Ligth'">Корды</th>
        <th v-if="selectThema == 'Scan'">Владелец</th>
      </thead>
      <tbody>
        <tr class="w-100 " v-if="load && reports.length < 1">
          <td class=""  style="background:#00000030" ></td>
          <td class="" style="background:#00000030" >загрузка...</td>
          <td class="" style="background:#00000030" >загрузка...</td>
          <td class="" v-if="selectThema == 'Scan'" style="background:#00000030" >загрузка...</td>
          <td class="" v-if="selectThema != 'Ligth'" style="background:#00000030" >загрузка...</td>
        </tr>
        <!-- selectThema=='Ligth' -->
        <tr class="w-100" v-if="selectThema=='Ligth' && reports.length == 0 && !load">
          <td class="py-1 pb-2" colspan="10" style="background:#00000030" > Нет маяков</td>
        </tr>
        <tr class="w-100" v-if="selectThema=='Fight' && reports.length == 0 && !load">
          <td class="py-1 pb-2" colspan="10" style="background:#00000030" > Нет докладов</td>
        </tr>
        <tr class="w-100" v-if="selectThema=='Scan' && reports.length == 0 && !load">
          <td class="py-1 pb-2" colspan="10" style="background:#00000030" > Нет отчетов</td>
        </tr>
          <tr  v-for="(report, index) in reports" @click="selectF(index), numSelect = index"  :key="report.id" :class="'table__row link '+((selectReport.id==report.id && selectReport.received == report.received)?'select':'')">
            <td class="text-right ">
              <div v-if="report.unopened" class="ml-2 curcl"></div>
            </td>
            <td v-if="report.received" >{{new Date(report.received*1000).toLocaleString("ru", )}}</td>
            <!-- <td>Система</td> -->
            <td  v-if="report.items"><span :title="report.items.planet_name" class="planet-name-wrapper">{{report.items.planet_name}}</span></td>
            <td  v-if="report.items">{{ report.items.planet_path ? '[' + report.items.planet_path.join(':') + ']' : '-' }}</td>
            <!-- <td v-if="selectThema == 'Scan'">{{Math.floor(report.items.report.resources?report.items.report.resources.silicon:0).toLocaleString('ru')}}</td> -->
            <td v-if="report.items && report.items.player_name !== undefined"><span :title="report.items.player_name" class="player-name-wrapper">{{ report.items.player_name || '-' }}</span></td>

            <td v-if="report.time" >{{new Date(Date.parse(report.time)).toLocaleString("ru", )}}</td>
            <td class="clip" v-if="report.message">{{report.message}}</td>


          </tr>
          <tr v-if="!isProVersion" class="table__row link">
            <td colspan="5">Больше отчетов доступно в PRO-версии</td>
          </tr>

      </tbody>
    </table>
  </div>
</template>

<script>
export default {

  props: {
    selectF: Function,
    selectT: Function,
    reports: Array,
    selectReport: Object,
    selectThema: String,
    loadingReport: Function,
    load: Boolean,
    newNotification: Object,
  },
  mounted(){
    document.querySelector(".collumn_report").addEventListener('scroll', ()=> {
      this.checkAjaxLoading()
    })
    document.addEventListener('keydown', this.listener); // здесь я его регистрирую

  },
  data(){
    return {
      numSelect: 0,

    }
  },
  computed: {
    isProVersion() {
      return this.$store.getters.isProVersion
    }
  },
  filters: {
    notifyCount: function (val) {
      let v = parseInt(val)

      if (isNaN(v) || v < 1) {
        return '0'
      }

      if (v > 99) {
        return '99+'
      }

      return v
    }
  },
  methods:{
    checkAjaxLoading(){
      var bottomCoord = document.querySelector('.table__row:last-child').getBoundingClientRect().bottom;
      var height = document.documentElement.clientHeight;
      if (height >= bottomCoord) {
        this.loadingReport()
      }
    },
    listener(e){
      if (e.code == 'ArrowUp' && this.numSelect > 0) {
        this.selectF(this.numSelect-=1)
      }
      if (e.code == 'ArrowDown' && this.numSelect < this.reports.length-1) {
        this.selectF(this.numSelect+=1)
      }
    },

  },
  beforeDestroy() {
        document.removeEventListener('keydown', this.listener); // здесь я его удаляю.
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.alink:hover{
  text-decoration: underline;
}
.curcl{

  border-radius: 50%;
  width: 5px;
  height: 5px;
  background: var(--left-curlc-bg);
}
.curcl_mess{
  min-width: 30px;
  max-width: 30px;
  text-align: center;
  font-size: 10px;
  padding: 6px 4px;
  right: 0;
  background: var(--left-curlc-mess-bg);
  color: var(--left-curlc-mess-color);
}

.noSelect{
  font-size: 13px;
  background: var(--left-noselect-bg);
  padding-left: 10px;
}
.selectTh{
  font-size: 13px;
  font-weight: 500;
  background: var(--left-selectth-bg);
  padding-left: 10px;
}
td{
  text-align: center;
  color: var(--left-td-color);
  font-size: 12px;
}
th{
  text-align: center;
}
.link{
  cursor: pointer;
}
.link:hover{
  background: var(--left-link-hover-bg);
}
::-webkit-scrollbar {  width: 10px;}

::-webkit-scrollbar-track-piece { background-color: var(--app-scrollbar-piece-bg-color);}
::-webkit-scrollbar-thumb { height: 50px; background-color: var(--app-scrollbar-thumb-bg-color);}

::-webkit-scrollbar-corner { background-color: var(--app-scrollbar-corner-bg-color);}
::-webkit-resizer { background-color: var(--app-resizer-bg-color);}

.collumn_report{
    max-width: 400px;
    height: calc(100vh);
    overflow: auto;
    background: var(--left-column-report-bg);
    color: var(--left-column-report-color);
  }
  .he1{
    font-size: 20px;
  }
  .select{
    background: var(--left-select-bg);
  }
  .clip{
    white-space: nowrap; /* Запрещаем перенос строк */
    overflow: hidden; /* Обрезаем все, что не помещается в область */
    text-overflow: ellipsis; /* Добавляем многоточие */
  }

.select-category {
  justify-content: center;
}

.select-category > div {
  align-items: center;
}

.planet-name-wrapper,
.player-name-wrapper {
  width: 80px;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
}
</style>
