<template>
<div class="col mx-1 main_box position-relative " v-if="selectReport.items.report.rounds && selectReport.items.report.rounds.length>0" >
    <div class="row">
        <div class="col-12 pt-1 pb-0 main_box_heigth">
            <p class="m-0 minfont">{{names[сname]}}:</p>
        </div>
        <div class="col-12 pl-3 pr-0 pb-0 main_box_heigth " v-if="selectReport.items.report.rounds[step][сname].squads_before && selectReport.items.report.rounds[step][сname].squads_before.length>0">
            <div class="row " >

        <div @click="selectUser=index" :class="'col-3 px-0  user ' + (selectUser==index?'select':'')" v-for="(floot, index) in selectReport.items.report.rounds[step][сname].squads_before"  :key="index">
          <p v-if="(floot.type=='fleet')&&(сname=='attacker')" class=" text-center ">{{floot.id}}</p>
          <p v-if="(floot.type=='fleet')&&(сname=='defender')" class=" text-center ">{{floot.owner}}</p>
          <p v-if="(floot.type=='defence' || floot.type =='building')" class=" text-center ">Планета</p>
                    <div class="d-flex px-0 ">
                        <div class="col gr text-center px-0" title="Оружие">{{floot.attack_level.toLocaleString('ru-RU')}}</div>
                        <div class="col ye text-center px-0" title="Броня">{{floot.armor_level.toLocaleString('ru-RU')}}</div>
                        <div class="col rd text-center px-0" title="Щиты">{{floot.shield_level.toLocaleString('ru-RU')}}</div>
                    </div>
                    <HP class="mx-auto" height="1" width="95%" :fill="checkFlootHp(selectReport.items.report.rounds[step][сname].squads_after[index], selectReport.items.report.rounds[0][сname].squads_before[index])" />
                </div>
            </div>


        </div>
    </div>

  <div class="row" v-if="!selectReport.items.report.rounds[step][сname].squads_before || selectReport.items.report.rounds[step][сname].squads_before.length<1">
    <div class="col-12 text-center mt-5">
      Нет данных
    </div>
  </div>

    <div class="row pb-2" v-if="selectReport.items.report.rounds[step][сname].squads_before && selectReport.items.report.rounds[step][сname].squads_before.length>0">

        <div class="col-3 mt-2 " v-for="unit in selectReport.items.report.rounds[step][сname].squads_before[selectUser].units"  :key="unit.type+unit.speed+unit.attack">
            <div class="minbg2 position-relative" :style="'background:url(/img/report/'+unit.type+'.jpg); background-position: center;background-repeat: no-repeat; background-size: cover;'">
                <div class="mask">
                    <div class="h-100 w-100 position-relative">
                        <span class="ml-2 mask_text ">{{namesUnit[unit.type]}}</span>
                        <span class="ml-auto mask_lvl yellow_main">{{unit.amount}}</span>
                    </div>
                </div>
            </div>
            <HP class="red " height="2" width="100%" :fill="chekHp(unit.type, 'armor')"  />
            <HP class="shild" height="2" style="margin-top: 2px;" width="100%" :fill="chekHp(unit.type, 'shield')"  />
        </div>

        <p class="text-center col-12 mt-2 mb-2" v-if="selectReport.items.report.rounds[step][сname].squads_after && selectReport.items.report.rounds[step][сname].squads_after[selectUser] && selectReport.items.report.rounds[step][сname].squads_after[selectUser].units.length < 1">
            Флот уничтожен
        </p>
    </div>

    <div class="row mt-auto bot_line" v-if="selectReport.items.report.rounds[step][сname].squads_after">
        <div class="col-6 py-1 main_box_1 d-flex justify-content-between">
            <span>Нанес урон:</span>
            <span class="yellow_main">{{selectReport.items.report.rounds[step][сname].absorb_damage.toLocaleString('ru-RU')}}</span>
        </div>
        <div class="col-6 py-1 main_box_2 d-flex justify-content-between">
            <span>Щиты поглотили:</span>
            <span class="yellow_main">{{selectReport.items.report.rounds[step][сname].received_damage.toLocaleString('ru-RU')}}</span>
        </div>
        <HP height="2" width="100%" :fill="fullHp" :brnot="true" />
    </div>

</div>
</template>

<script>
import HP from '@/components/report/compon/HpComponent.vue'
import nameUnits from '@/libs/nameunits'

export default {
    created(){
        this.checkFirstHp()
    },
    mounted(){
        // console.log(this.selectReport.items.report.rounds.length);
        // this.heighted = document.querySelector('.main_box').offsetHeight
    },
    props: {
        selectReport: Object,
        сname: String,
        step: Number,
    },
        components:{
        HP,
    },
    data() {
        return {
            countUnit: {},
            // heighted: '',
            names: {attacker:"Атакующие флоты", defender:"Обороняющиеся флоты", draw: "Ничья",},
            namesUnit: nameUnits,
            selectFloot:{attacker:0, defender:0},
            selectUser: 0,
            idTime: '',
        }
    },

    computed:{
        fullHp(){      // считает полный хп обороняющегося и атакующего
            let armor = 0, shield = 0, countUnit = 0
            let firstArmor = 0, firstShield = 0, firstCountUnit = 0
            this.selectReport.items.report.rounds[0][this.сname].squads_before.forEach(user => {
                firstCountUnit += this.countUnit[user.type+user.id]
                user.units.forEach((unit) => {
                    firstArmor = firstArmor + unit.armor
                    firstShield = firstShield + unit.shield
                })
            })
            this.selectReport.items.report.rounds[this.step][this.сname].squads_after.forEach(user => {
                countUnit += this.countUnit[user.type+user.id]
                user.units.forEach((unit) => {
                    armor = armor + unit.armor
                    shield = shield + unit.shield
                })
            })
            let fulHp = (shield+armor)/(2*countUnit)
            if (isNaN(fulHp)) {
                fulHp = 0
            }
            let firstFulHp = (firstArmor+firstShield)/(2*firstCountUnit)
            return String(fulHp/firstFulHp*100)
        }
    },
    methods:{
        checkFlootHp(user, firstUser){     // расчитывает hp у отдельных флотов
            if (!user)
                return String(0)
            let SumArmor = 0
            let SumArmorFull = 0
                user.units.forEach((unit) => {
                    SumArmor += unit.armor
                    SumArmor += unit.shield
                })
            // let fulHp = (SumArmor)/(2*this.countUnit[user.type+user.id])
            firstUser.units.forEach((unit) => {
                SumArmorFull += unit.armor
                SumArmorFull += unit.shield
            })
            // console.log( );
            return String(SumArmor/SumArmorFull*100)
        },
        checkFirstHp(){  //запоминает начальное количество кораблей
            if (this.selectReport.items.report.rounds && this.selectReport.items.report.rounds.length>0 && this.selectReport.items.report.rounds[0][this.сname].squads_before) {
                this.selectReport.items.report.rounds[0][this.сname].squads_before.forEach(user => {
                    this.countUnit[user.type+user.id] = user.units.length
                })
            }

        },
        checkDie(type){
            if (this.selectReport.items.report.rounds[this.step][this.сname].squads_after[this.selectUser]) {
                let ans = {}
                ans.after = this.selectReport.items.report.rounds[this.step][this.сname].squads_after[this.selectUser].units.find(item => item.type == type)
                ans.before = this.selectReport.items.report.rounds[0][this.сname].squads_after[this.selectUser].units.find(item => item.type == type)
                return ans
            }
        },
        chekHp( type, shildOrArmor){    //расчитывает hp отдельных кораблей
            let unit = this.checkDie(type)
            // console.log(unit.before);
            if (unit == undefined || unit.after == undefined) {
                return String(0)
            }
            if (unit.before[shildOrArmor] == 0 || unit.before[shildOrArmor] == null) {
                // console.log(type+':'+shildOrArmor, 0)
                return '-1'
            }
            // console.log(type+':'+shildOrArmor, unit.before[shildOrArmor], unit.after[shildOrArmor] );
            return String(unit.after[shildOrArmor]/unit.before[shildOrArmor]*100)
            // }
            // return '0'
            // return String((unit.after.shield+unit.after.armor)/2)
        },
    },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.minbg2{
    height: 80px;
}
.mask_text{
    overflow-wrap: anywhere;
    position: absolute;
    left: -5px;
    bottom:0;
    font-size: 10px;
}
.mask_lvl{
    position:absolute;
    left: 5px;
    top: 0;
}
.mask{
    position: absolute;
    /* background: ; */
    background: var(--card-mask-bg);
    left: 0;
    right: 0;
}
p{
  font-size:15px;
  margin: 0;
}
span{
  font-size: 15px;
}
.yellow_main{
  color: var(--card-yellow-main);
}
.main_box_heigth{
  padding: 5px 10px;
  background: var(--card-main-box-bg);
}
.main_box_1{
  background: var(--card-main-box1-bg);
}
.main_box_2{
  background: var(--card-main-box2-bg);
}
.bot_line{
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 10;
}
.minfont{
  font-size: 11px;
}
.user{
  line-height: 1.2;
  font-size: 13px!important;
  cursor: pointer;
}
.user.select{
  background: var(--card-user-select-bg);
}
.gr{
  line-height: 1.2;
  background: var(--card-gr-bg);
  border: 1px solid var(--card-gr-border-color);
  margin-left: 3px;
 }
.ye{
  line-height: 1.2;
  background: var(--card-ye-bg);
  border: 1px solid var(--card-ye-border-color);
}
.rd{
  line-height: 1.2;
  background: var(--card-rd-bg);
  border: 1px solid var(--card-rd-border-color);
  margin-right: 3px;
 }
 .floot{
   background: var(--card-floot-bg);
 }
 .floot_one.select{
   border-bottom: 1px solid var(--card-floot-border-color);
   font-weight: 500;
 }
.user.select>p{
  color: var(--card-floot-user-select-color);
}

</style>
