import { render, staticRenderFns } from "./HpComponent.vue?vue&type=template&id=127c5994&scoped=true&"
import script from "./HpComponent.vue?vue&type=script&lang=js&"
export * from "./HpComponent.vue?vue&type=script&lang=js&"
import style0 from "./HpComponent.vue?vue&type=style&index=0&id=127c5994&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "127c5994",
  null
  
)

export default component.exports